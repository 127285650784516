import { toJpeg } from 'html-to-image';
import * as Sentry from "@sentry/react";
import jsPDF from 'jspdf';

export const generatePDF = async (form, id) => {
    try {
        const dataUrl = await toJpeg(form, {
            style: {
              transform: 'scale(1)',
            }
        });

        const pdf = new jsPDF({
          orientation: 'portrait',
          unit: 'pt',
          format: 'a4',
        });
    
        // Get image dimensions (this is just an example, adjust as needed)
        const imgWidth = pdf.internal.pageSize.getWidth();
        const imgHeight = pdf.internal.pageSize.getHeight();
    
        // Add image to PDF
        pdf.addImage(dataUrl, 'JPEG', 0, 0, imgWidth, imgHeight);
    
        // Generate the PDF file as a Blob
        const pdfBlob = pdf.output('blob');
    
        // Convert Blob to File (if needed)
        const file = new File([pdfBlob], `${id}.pdf`, { type: 'application/pdf' });
        return file;
        
    } catch (error) {
      Sentry.captureException(error);
    }
}

const dataURLtoBlob = (dataUrl) => {
    const [header, base64String] = dataUrl.split(',');
    const byteString = atob(base64String);
    const mimeString = header.split(':')[1].split(';')[0];
  
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const intArray = new Uint8Array(arrayBuffer);
  
    for (let i = 0; i < byteString.length; i++) {
      intArray[i] = byteString.charCodeAt(i);
    }
  
    return new Blob([intArray], { type: mimeString });
  }
  
  // Function to convert Blob to File
  const blobToFile = (blob, fileName) => {
    return new File([blob], fileName, { type: blob.type });
  }
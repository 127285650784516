import axios from "axios";
import { createFormData } from "../utils/helper";

export const getWarranties = async (props) => {
    try {
        const response = await axios.get(`${import.meta.env.VITE_API_URL}/users/${props.data.id}/warranties`, {
            headers: {
                Authorization: `Bearer ${props.token}`
            }
        });
        return response.data.data;
    } catch (error) {
        if(error.response) {
            throw new Error(error.response.data.message);
        }
        throw new Error(error.message);
    }
}

export const getWarranty = async (props) => {
    try {
        const response = await axios.get(`${import.meta.env.VITE_API_URL}/users/warranties/${props.id}`, {
            headers: {
                Authorization: `Bearer ${props.user.token}`
            }
        });;
        return response.data.data;
    } catch (error) {
        if(error.response) {
            throw new Error(error.response.data.message);
        }
        throw new Error(error.message);
    }
}

export const createWarranty = async (props) => {
    try {
        const response = await axios.post(`${import.meta.env.VITE_API_URL}/users/${props.user.data.id}/warranties`, props.data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${props.user.token}`
              },
        });
        return response.data.data;
    } catch (error) {
        if(error.response) {
            throw new Error(error.response.data.message);
        }
        throw new Error(error.message);
    }
}

export const updateWarranty = async (props) => {
    try {
        const formData = createFormData(props.data);
        
        const response = await axios.put(`${import.meta.env.VITE_API_URL}/users/${props.user.data.id}/warranties`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${props.user.token}`
              },
        });
        return response.data.data;
    } catch (error) {
        if(error.response) {
            throw new Error(error.response.data.message);
        }
        throw new Error(error.message);
    }
}

export const deleteWarranty = async (props) => {
    try {
        const response = await axios.delete(`${import.meta.env.VITE_API_URL}/users/warranties/${props.id}`, {
            headers: {
                Authorization: `Bearer ${props.user.token}`
            }
        });
        return response.data.data;
    } catch (error) {
        if(error.response) {
            throw new Error(error.response.data.message);
        }
        throw new Error(error.message);
    }
}

export const searchWarranties = async (props) => {
    try {
        const response = await axios.post(`${import.meta.env.VITE_API_URL}/users/${props.user.data.id}/warranties/search`,
         {
            fields: props.fields, 
            query: props.query
         }, {
            headers: {
                Authorization: `Bearer ${props.user.token}`
            }
         });
        return response.data.data;
    } catch (error) {
        if(error.response) {
            throw new Error(error.response.data.message);
        }
        throw new Error(error.message);
    }
}
import "../assets/styles/dashboard.css";
import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import Card from "../components/Card/Card";
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import CustomAreaChart from "../components/AreaChart/CustomAreaChart";
import { AttachMoneyOutlined } from "@mui/icons-material";
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined';
import Dropdown from "../components/Dropdown/Dropdown";
import { useQueries } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { getRepairs } from "../services/repairService";
import { getWarranties } from "../services/warrantyService";
import { getDataByOption } from "../utils/helper";
import { getDashboardCharts, getExpensesByOption } from "../services/expenseService";
import { set } from "react-hook-form";
import LoadingSpinner from "../components/LoadingSpinner/LoadingSpinner";

const Dashboard = () => {
    const user = useSelector((state) => state.auth.user);
    const [totalIncome, setTotalIncome] = useState(0);
    const [totalJobs, setTotalJobs] = useState(0);
    const [totalWarranties, setTotalWarranties] = useState(0);
    const [incomeCharts, setIncomeCharts] = useState([]);
    const [option, setOption] = useState("All Time");

    const results = useQueries({
        queries: [
            {
                queryKey: ["expenses", user.data.id],
                queryFn: () => getDashboardCharts(user),
                refetchOnWindowFocus: false
            },
            {
                queryKey: ["repairs", user.data.id],
                queryFn: () => getRepairs(user),
                refetchOnWindowFocus: false
            },
            {
                queryKey: ["warranties", user.data.id],
                queryFn: () => getWarranties(user),
                refetchOnWindowFocus: false
            }
        ]
    });

    const [expensesResult, jobsResult, warrantiesResult] = results;

    const isLoading = results.some(result => result.isLoading);
    const isError = results.some(result => result.isError);

    useEffect(() => {
        if (expensesResult.data) {
            getIncomeTotal();
        }
    }, [expensesResult.data]);

    useEffect(() => {
        if (jobsResult.data) {
            getRepairsTotal();
        }
    }, [jobsResult.data]);

    useEffect(() => {
        if (warrantiesResult.data) {
            getWarrantiesTotal();
        }
    }, [warrantiesResult.data]);

    useEffect(() => {
        if(results.every(result => result.isSuccess)) {
            getRepairsTotal();
            getWarrantiesTotal();
            getIncomeTotal();
        }
    }, [option]);

    const getIncomeTotal = async () => {
        const expenses = await getExpensesByOption(expensesResult.data, option);
        setIncomeCharts(expenses.charts);
        setTotalIncome(expenses.income);

    }

    const getRepairsTotal = () => {
        const jobs = getDataByOption(jobsResult.data, option);
        setTotalJobs(jobs.length);
    }

    const getWarrantiesTotal = () => {
        const warranties = getDataByOption(warrantiesResult.data, option);
        setTotalWarranties(warranties.length);
    }

    const handleOption = (value) => {
        setOption(value);
    }

    if (isLoading) {
        return (
            <Layout activeLink="dashboard">
                <div className="shared_title_content">
                    <h3 className="shared_title">Dashboard</h3>
                </div>
                <div className="shared_loading_spinner_container">
                    <LoadingSpinner color="grey" size="32px" />
                </div>
            </Layout>
        );
    }

    return (
        <Layout hideSearchBar={true} activeLink={"dashboard"}>
            <div className="dashboard_content">
                <div className="dashboard_header">
                    <div className="dashboard_title">
                        <h2>Dashboard</h2>
                    </div>
                    <Dropdown defaultVal={option} onChange={handleOption} options={["All Time", "Yearly", "Monthly", "Weekly", "Daily"]}/>
                </div>
                <div className="dashboard_data">
                    <div className="dashboard_cards">
                        <div className="dashboard_cards_row">
                            <Card title="Total Jobs" icon={<WorkOutlineOutlinedIcon fontSize="large"/>}>
                                <p>{totalJobs}</p>
                            </Card>
                            <Card title="Total Warranties" icon={<ContentPasteOutlinedIcon fontSize="large"/>}>
                                <p>{totalWarranties}</p>
                            </Card>
                            <Card title="Total Income" icon={<AttachMoneyOutlined fontSize="large"/>}>
                                <p>${totalIncome}</p>
                            </Card>
                        </div>
                    </div>
                    <div className="dashboard_chart">
                        <div className="dashboard_chart_title">Income Summary</div>
                        <CustomAreaChart data={incomeCharts}/>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Dashboard;

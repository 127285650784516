import React, { useRef, useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import './FileUpload.css';
import PDFIcon from '../../assets/images/pdf.svg';
import PNGIcon from '../../assets/images/png.svg';
import JPEGIcon from '../../assets/images/jpeg.svg';
import JPGIcon from '../../assets/images/jpg.svg';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

const FileUpload = forwardRef(({setValue, name,  defaultFiles }, ref) => {

    const fileInputRef = useRef(null);
    const [files, setFiles] = useState([]);

    useEffect(() => {
        if (defaultFiles.length > 0) {
            setFiles(defaultFiles);
            setValue(name, defaultFiles); // Set default values in the form
        }
    }, [defaultFiles, setValue, name]);

    
    useImperativeHandle(ref, () => ({
        click: () => {
            fileInputRef.current.click();
        }
    }));

    const handleClick = () => fileInputRef.current.click();

    const handleChange = (event) => {
        const newFiles = Array.from(event.target.files);
        setFiles(prevFiles => {
            const updatedFiles = [...prevFiles, ...newFiles];
            setValue(name, updatedFiles);
            return updatedFiles; 
        });
        
       event.target.value = null;
    };

    const handleRemoveFile = (index) => {
        setFiles(prevFiles => {
            const updatedFiles = prevFiles.filter((_, i) => i !== index);
            setValue(name, updatedFiles);
            return updatedFiles;
        });
    };

    const getExtension = (fileName) => {
        const parts = fileName.split('.');
        return parts.length > 1 ? parts[parts.length - 1].toLowerCase() : '';
    };

    const getIcon = (file) => {
        const extension = getExtension(file.name);

        switch (extension) {
            case 'pdf':
                return PDFIcon;
            case 'png':
                return PNGIcon;
            case 'jpeg':
                return JPEGIcon;
            case 'jpg':
                return JPGIcon;
            default:
                return null;
        }
    };

    return (
        <div className="fileupload_container">
            <div className='fileupload_body' onClick={handleClick}>
                <input
                    type="file"
                    multiple
                    className="fileupload_input"
                    accept='.pdf,.png,.jpeg,.jpg'
                    onChange={handleChange}
                    ref={fileInputRef}                 
                />
                <div className="fileupload_content">
                    <FileUploadOutlinedIcon fontSize='large'/>
                    <p className='fileupload_title'>Click or Drag to Upload</p>
                    <p className='fileupload_subtitle'>PDF, JPEG, JPG, and PNG formats</p>
                </div>
            </div>
            <div className="fileupload_files">
                {files.map((file, index) => (
                    <div key={index} className="fileupload_file">
                        <img width={48} height={48} src={getIcon(file)} alt={file.name} />
                        <div>
                            <p className='fileupload_name'>{file.name}</p>
                            <p className='fileupload_size'>{(file.size / 1000).toFixed(2)} KB</p>
                        </div>
                        <HighlightOffOutlinedIcon className='fileupload_close' onClick={() => handleRemoveFile(index)} />
                    </div>
                ))}
            </div>
        </div>
    );
});

export default FileUpload;

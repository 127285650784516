import { CheckCircleOutlineOutlined, ErrorOutlineOutlined } from "@mui/icons-material";
import "./SettingItem.css";
import { forwardRef } from "react";
const SettingItem = forwardRef(({ title, disabled, error, onChange, type = "text", register }, ref) => {
    let className = "setting_input";

    if (disabled) {
        className += " setting_input_disabled";
    }

    if (error) {
        className += " setting_input_error";
    }

    return (
        <div className="setting_item">
            <p className="setting_title">{title}</p>
            <input 
            className={className} 
            type={type} 
            disabled={disabled} 
            onChange={onChange}
            ref={ref}
            {...(register ? register : {})} />

            {error && <ErrorOutlineOutlined className="setting_error_icon" />}
        </div>
    );
});

export default SettingItem;
import React, { forwardRef } from "react";
import "./Checkbox.css";

const Checkbox = forwardRef(({ label, checked, register, onChange }, ref) => {
    return (
        <div className="checkbox_container">
            <input type="checkbox" className="checkbox_input"
             checked={checked} 
             onChange={onChange} 
             ref={ref}
             {...(register ? register : {})}
             />
            <div>{label}</div>
        </div>
    );
});

export default Checkbox;
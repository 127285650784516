import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: `${import.meta.env.VITE_SENTRY_URL}`,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    
   // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
  
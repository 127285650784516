import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import "./SettingItem.css";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Button from "../Button/Button";

const SettingProfilePictureItem = forwardRef(({title, error, setValue, profilePicture}, ref) => {
    const inputRef = useRef(null);
    const defaultProfilePicture = "https://gudinosrepair.s3.us-west-1.amazonaws.com/dev/Default_pfp.jpg"
    const [imagePreview, setImagePreview] = useState(profilePicture);

    useImperativeHandle(ref, () => ({
        click: () => {
            inputRef.current.click();
        }
    }));

    const handleEditClick = () => {
        inputRef.current.click();
    }

    const handleDeleteClick = () => {
        setImagePreview(defaultProfilePicture);
        setValue("profilePicture", defaultProfilePicture);
    }


    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setImagePreview(URL.createObjectURL(file));
        setValue("profilePicture", file);
    };
    return (
        <div className="setting_item">
            <p className="setting_title">{title}</p>
            <div className="setting_profile_picture_container">
                <img className="setting_profile_picture" width={100} height={100} src={imagePreview} />
                <EditOutlinedIcon className="setting_edit_icon" onClick={handleEditClick} />
                <input 
                ref={inputRef} 
                type="file" 
                accept="image/*" 
                style={{display: "none"}} 
                onChange={handleFileChange}
                />
            </div>
            <button className="setting_delete_button" type="button" onClick={handleDeleteClick}>
                Delete
            </button>
            {error && <ErrorOutlineOutlined className="setting_error_icon" />}
        </div>
    )
})

export default SettingProfilePictureItem;
import axios from "axios";

export const getExpenses = async (props) => {
    try {
        const response = await axios.get(`${import.meta.env.VITE_DEV_API_URL}/users/${props.data.id}/expenses`, {
            headers: {
                Authorization: `Bearer ${props.token}`
            }
        });
        return response.data.data;
    } catch (error) {
        if(error.response) {
            throw new Error(error.response.data.message);
        }
        throw new Error(error.message);
    }
}

export const getDashboardCharts = async (props) => {
    try {
        const response = await axios.get(`${import.meta.env.VITE_API_URL}/users/${props.data.id}/dashboard/charts`, {
            headers: {
                Authorization: `Bearer ${props.token}`
            }
        });
        return response.data.data;
    } catch (error) {
        if(error.response) {
            throw new Error(error.response.data.message);
        }
        throw new Error(error.message);
    }
}

export const getExpensesByOption = async (expenses, option) => {
    switch (option) {
        case "Yearly":
            return expenses.yearly
        case "Monthly":
            return expenses.monthly
        case "Weekly":
            return expenses.weekly;
        case "Daily":
            return expenses.daily;
        default:
            return expenses.allTime;
    }
};

export const getFormattedExpenses = async (props) => {
    try {
        const response = await axios.get(`${import.meta.env.VITE_DEV_API_URL}/users/${props.data.id}/expenses/formatted`, {
            headers: {
                Authorization: `Bearer ${props.token}`
            }
        });
        return response.data.data;
    } catch (error) {
        if(error.response) {
            throw new Error(error.response.data.message);
        }
        throw new Error(error.message);
    }
}

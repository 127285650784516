import { createReceipt, getReceipt, updateReceipt } from "../services/receiptService";
import { createRepair, getRepair, updateRepair } from "../services/repairService";
import { createWarranty, getWarranty, updateWarranty } from "../services/warrantyService";

export const formConfigs = {
    '/jobs/add': {
        fields: [
            { label: 'Customer Name', name: 'customer', type: 'text', placeholder: 'Customer Name', error: 'Customer Name is required'},
            { label: 'Car', name: 'car', type: 'text', placeholder: 'Car', error: 'Car is required'},
            { label: 'Job', name: 'job', type: 'text', placeholder: 'Job', error: 'Job is required'},
            { label: 'Price', name: 'price', type: 'text', placeholder: 'Price', group: '1', error: 'Price is required'},
            { label: 'Expenses', name: 'expenses', type: 'text', placeholder: 'Expenses', group: '1', error: 'Expenses is required'},
            { label: 'Date', name: 'date', type: 'date', placeholder: 'Date', error: 'Date is required'},
            { label: 'Upload File', name:"File", type: 'file'},
        ],
        header: "Create New Job",
        active: "jobs",
        mutationFn: createRepair
    },

    '/jobs/edit': {
        fields: [
            { label: 'Customer Name', name: 'customer', type: 'text', placeholder: 'Customer Name', error: 'Customer Name is required'},
            { label: 'Car', name: 'car', type: 'text', placeholder: 'Car', error: 'Car is required'},
            { label: 'Job', name: 'job', type: 'text', placeholder: 'Job', error: 'Job is required'},
            { label: 'Price', name: 'price', type: 'text', placeholder: 'Price', group: '1', error: 'Price is required'},
            { label: 'Expenses', name: 'expenses', type: 'text', placeholder: 'Expenses', group: '1', error: 'Expenses is required'},
            { label: 'Date', name: 'date', type: 'date', placeholder: 'Date', error: 'Date is required'},
            { label: 'Upload File', name:"repairDocuments", type: 'file'},
        ],
        header: "Edit Job",
        active: "jobs",
        getData: getRepair,
        mutationFn: updateRepair
    },

    '/warranties/add': {
        fields: [
            { label: "Car", name: "car", type: "text", placeholder: "Car", error: "Car is required"},
            { label: "Part", name: "part", type: "text", placeholder: "Part", error: "Part is required"},
            { label: "Part Number", name: "partNumber", type: "text", placeholder: "Part Number", group: '1', error: "Part Number is required"},
            { label: "Warranty Period", name: "warrantyPeriod", type: "text", placeholder: "Warranty Period", group: '1', error: "Warranty Period is required"},
            { label: "Store", name: "store", type: "text", placeholder: "Store", error: "Store is required"},
            { label: "Date", name: "date", type: "date", placeholder: "Date", error: "Date is required"},
            { label: 'Upload File', name:"File", type: 'file'},

        ],
        header: "Create New Warranty",
        active: "warranties",
        mutationFn: createWarranty
    },

    '/warranties/edit': {
        fields: [
            { label: "Car", name: "car", type: "text", placeholder: "Car", error: "Car is required"},
            { label: "Part", name: "part", type: "text", placeholder: "Part", error: "Part is required"},
            { label: "Part Number", name: "partNumber", type: "text", placeholder: "Part Number", group: '1', error: "Part Number is required"},
            { label: "Warranty Period", name: "warrantyPeriod", type: "text", placeholder: "Warranty Period", group: '1', error: "Warranty Period is required"},
            { label: "Store", name: "store", type: "text", placeholder: "Store", error: "Store is required"},
            { label: "Date", name: "date", type: "date", placeholder: "Date", error: "Date is required"},
            { label: 'Upload File', name:"warrantyDocuments", type: 'file'},

        ],
        header: "Edit Warranty",
        active: "warranties",
        getData: getWarranty,
        mutationFn: updateWarranty
    },

    '/expenses/add': {
        fields: [
            
        ],
        header: "Create New Expense",
        active: "expenses"
    },

    '/receipts/add': {
        fields: [
            { label: "Date", name: "date", type: "date", placeholder: "Date", error: "Date is required"},
            { label: "Notes", name: "notes", type: "textarea", placeholder: "Enter any notes!"},
            { label: 'Upload File', name:"File", type: 'file'},
        ],
        header: "Upload New Receipts",
        active: "Receipts",
        mutationFn: createReceipt
    },

    '/receipts/edit': {
        fields: [
            { label: "Date", name: "date", type: "date", placeholder: "Date", error: "Date is required"},
            { label: "Notes", name: "notes", type: "textarea", placeholder: "Enter any notes!"},
            { label: 'Upload File', name:"receiptDocuments", type: 'file'},
        ],
        header: "Edit Receipt",
        active: "Receipts",
        getData: getReceipt,
        mutationFn: updateReceipt
    }
}
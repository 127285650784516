import axios from "axios";
import moment from "moment";
import { useDispatch } from "react-redux";
import { logout } from "../reducers/authReducer";

export const validateToken = async () => {
    const dispatch = useDispatch();
    const savedState = JSON.parse(sessionStorage.getItem('state'));
    if(savedState && savedState.auth.user) {
        const token = savedState.auth.user.token;
        const headers = {
            'Authorization': `Bearer ${token}`
        }
        try {
            const response = await axios.post(`${import.meta.env.VITE_API_URL}/users/validate/token`, {}, {headers} );
            if(response.status === 200) {
                return true;
            }
        } catch (error) {
            dispatch(logout());
        }
    }
}

export const getBasePath = (pathname) => {
    const parts = pathname.split('/');
    if (parts[parts.length - 1].match(/^\d+$/)) {
      parts.pop();
    }
    return parts.join('/');
  };

export const getDataByOption = (data, option) => {
    const now = moment();
    switch (option) {
        case 'All Times':
            return data;
        case 'Yearly':
            return data.filter(d => 
                moment(d.date, 'MM-DD-YYYY').isSame(now, 'year')
            );
        case 'Monthly':
            return data.filter(d => 
                moment(d.date, 'MM-DD-YYYY').isSame(now, 'month')
            );
        case 'Weekly':
            return data.filter(d => 
                moment(d.date, 'MM-DD-YYYY').isSame(now, 'week')
            );
        case 'Daily':
            return data.filter(d => 
                moment(d.date, 'MM-DD-YYYY').isSame(now, 'day')
            );
        default:
            return data;
    }
}

export const getWeekBounds = (today) => {
    const now = today ? today : new Date();

    const dayOfWeek = now.getDay(); // 0 (Sun) to 6 (Sat)
    const dayOfMonth = now.getDate();
    
    // Calculate the difference to get to Monday
    const diffToMonday = (dayOfWeek === 0 ? -6 : 1) - dayOfWeek;
    
    // First day of the week (Monday)
    const firstDayOfWeek = new Date(now.setDate(dayOfMonth + diffToMonday));

    // Last day of the week (Sunday)
    const lastDayOfWeek = new Date(firstDayOfWeek);
    lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);

    return { firstDayOfWeek, lastDayOfWeek };
}

export const createChartData = (data) => {
    const chartMap = new Map();
    
    // Populate the Map
    for (let i = 0; i < data.length; i++) {
        const date = getMonthName(data[i].startMonth);
        data[i].income = parseFloat(data[i].income);
        if (chartMap.has(date)) {
            chartMap.set(date, (parseFloat(chartMap.get(date)) + (data[i].income)).toFixed(2));
        } else {
            chartMap.set(date, (data[i].income).toFixed(2));
        }
    }

    // Convert the Map to an array of objects
    const chartArray = Array.from(chartMap, ([date, income]) => ({ date, income }));
    return chartArray;
}

export const getMonthName = (month) => {
    return moment().month(month - 1).format('MMMM');
}
export const createFormData = (data) => {
    const formData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
        if (Array.isArray(value)) {
            // Handle arrays
            value.forEach((item, index) => {
                if (item instanceof File) {
                    formData.append(`${key}[${index}]`, item, item.name);
                } else if (item instanceof Object) {
                    // If the item is an Object, flatten and append its properties
                    Object.entries(item).forEach(([subKey, subValue]) => {
                        formData.append(`${key}[${index}][${subKey}]`, subValue);
                    });
                } else {
                    // For other types (e.g., primitive values), append directly
                    formData.append(`${key}[${index}]`, item);
                }
            });
        } else if (value instanceof File) {
            // Handle single File instances
            formData.append(key, value, value.name);
        } else if (value instanceof Object) {
            // Handle nested objects by flattening and appending their properties
            Object.entries(value).forEach(([subKey, subValue]) => {
                formData.append(`${key}[${subKey}]`, subValue);
            });
        } else {
            // Handle primitive values
            formData.append(key, value);
        }
    });
    return formData;
}

export const formatCurrency = (value) => {
    if(value != undefined) {
        if(value == "") {
            return "";
        }
        const cleanedValue = value.replace(/[^0-9.-]+/g, '');
        let parsedValue = parseFloat(cleanedValue);
        if (isNaN(parsedValue)) {
            parsedValue = "0";
        }
        return parseFloat(parsedValue).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }
}

export const addCurrencyValues = (value1, value2) => {
    const num1 = parseFloat(value1.replace(/[^0-9.-]+/g, ''));
    const num2 = parseFloat(value2.replace(/[^0-9.-]+/g, ''));
    
    if (isNaN(num1) || isNaN(num2)) {
        return 0
    }

    const sum = num1 + num2;
    
    // Format the sum back to currency
    return sum.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
};



import React, { useState, useEffect, Fragment } from "react";
import Table from "../components/Table/Table";
import "../assets/styles/shared.css";
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import Pagination from "../components/Pagination/Pagination";
import Layout from "../components/Layout/Layout";
import Button from "../components/Button/Button";
import Modal from "../components/Modal/Modal";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import LoadingSpinner from "../components/LoadingSpinner/LoadingSpinner";
import { useMutation, useQuery } from "@tanstack/react-query";
import * as Sentry from "@sentry/react";
import Error from "../components/Error/Error";
import { deleteInvoice, getInvoices } from "../services/invoiceService";

const Invoices = () => {
    const user = useSelector((state) => state.auth.user);
    const [invoicesToDisplay, setInvoicesToDisplay] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [entriesPerPage, setEntriesPerPage] = useState(25);
    const [totalPages, setTotalPages] = useState(0);
    const [totalValues, setTotalValues] = useState(0);
    const [searchResults, setSearchResults] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [invoiceToDelete, setInvoiceToDelete] = useState(null);

    const navigate = useNavigate();
    const mutation = useMutation({mutationFn: deleteInvoice});

    const { isLoading, isError, error, data: allInvoices, refetch } = useQuery({
        queryKey: ["invoices", user.data.id],
        queryFn: () => getInvoices(user),
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if (allInvoices) {
            const results = searchValue ? searchResults : allInvoices;
            const { invoices, totalPages, totalValues } = getInvoicesToDisplay(results);
            setInvoicesToDisplay(invoices);
            setTotalPages(totalPages);
            setTotalValues(totalValues);
        }
    }, [allInvoices, searchResults, searchValue, entriesPerPage, currentPage]);

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    const getInvoicesToDisplay = (invoicesList) => {
        const totalPages = Math.ceil(invoicesList.length / entriesPerPage);
        const totalValues = invoicesList.length;
        const start = (currentPage - 1) * entriesPerPage;
        const end = start + entriesPerPage;
        const invoices = invoicesList.slice(start, end);
        return { invoices, totalPages, totalValues };
    };

    const handleSearch = async (searchValue) => {
        setSearchValue(searchValue);
        if (searchValue === "") {
            setSearchResults([]);
        } else {
            const results = await searchRepairs({user, fields: ["customer"], query: searchValue});
            setSearchResults(results);
        }
        setCurrentPage(1); // Reset to the first page after a search
    };

    const headers = {
        main: [
        { label: "Invoice ID", name: "id" },
        { label: "Customer", name: "name" },
        { label: "Date", name: "date" },
        { label: "Actions", name: "actions", actions: ["edit", "delete", "view"]}
        ]
    };

    const onSearch = async (e) => {
        const searchValue = e.target.value;
        await handleSearch(searchValue);
    };

    const handleView = (invoice) => {
        window.open(invoice.url, '_blank', 'noopener,noreferrer');
    }

    const handleEdit = (invoice) => {
        navigate(`/invoices/edit/${invoice.id}`);
    };

    const handleDelete = () => {
        setShowDeleteModal(!showDeleteModal);
        mutation.mutate(({id: invoiceToDelete.id, user}), {
            onSuccess: () => {
                setInvoiceToDelete(null);
                refetch();
            },
            onError: (error) => {
                Sentry.captureException(error);
            }
        });
    }

    const handlePressDelete = (invoice) => {
        setInvoiceToDelete(invoice);
        setShowDeleteModal(!showDeleteModal);
    }

    const handleCancel = () => {
        setShowDeleteModal(!showDeleteModal);
    }

    const navigateToCreateInvoices = () => {
        navigate("/invoices/add");
    };

    const deleteModal = () => {
        return (
            <Modal show={showDeleteModal} onClose={handleCancel}>
                <div className="shared_delete_modal_container">
                    <div>
                        <div className="shared_delete_icon_container">
                            <WarningAmberOutlinedIcon className="shared_delete_icon" style={{fontSize: "5rem" }} />
                        </div>

                        <h3 style={{textAlign: 'center'}}>Are you sure?</h3>
                        <p>This action cannot be undone! This will permenanetly delete<br/> <b>{invoiceToDelete.id}</b> and any data related to it</p>
                        <div className="shared_delete_modal">
                            <Button title="Cancel" onClick={handleCancel} style={{backgroundColor: "white", color: "black", border: "1px solid grey"}} type="button" />
                            <Button title="Delete" onClick={handleDelete} style={{backgroundColor: "#FF6F6F"}} type="button" />
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }

    if (isLoading) {
        return (
            <Layout activeLink="invoices">
                <div className="shared_title_content">
                    <h3 className="shared_title">Invoices</h3>
                </div>
                <div className="shared_loading_spinner_container">
                    <LoadingSpinner color="grey" size="32px" />
                </div>
            </Layout>
        );
    }

    if (isError) {
            Sentry.captureException(error);
        return (
            <Layout activeLink="invoices">
                <div className="shared_title_content">
                    <h3 className="shared_title">Invoices</h3>
                </div>
                <Error/>
            </Layout>
        );
    }

    return (
        <Layout activeLink="invoices" searchBarOnChange={onSearch}>
            <div className="shared_title_content">
                <h3 className="shared_title">Invoices</h3>
                <div className="shared_add_button">
                    <Button title="Create Invoice" onClick={navigateToCreateInvoices} type="button" />
                </div>
            </div>
            {showDeleteModal && deleteModal()}
                <Fragment>
                    <div className="shared_table">
                        <Table headers={headers} onEdit={handleEdit} onView={handleView} onDelete={handlePressDelete} data={invoicesToDisplay} />
                    </div>
                    {totalValues > 0 && (
                        <div className="shared_pagination">
                            <p className="job_total">
                                Showing {entriesPerPage * (currentPage - 1) + 1} to{" "}
                                {Math.min(entriesPerPage * currentPage, totalValues)} of {totalValues} entries
                            </p>
                            <Pagination totalPages={totalPages} pCurrentPage={currentPage} onPageChange={onPageChange} />
                        </div>
                    )}
                </Fragment>
        </Layout>
    );
};

export default Invoices;
import axios from "axios";

export const loginUser = async (data) => {
    try {

      const response = await axios.post(`${import.meta.env.VITE_API_URL}/users/login`, data)
      return response.data;
    } catch (error) {
        if(error.response) {
            throw new Error(error.response.data.message);
        }
      throw new Error(error.message);
    }
  };
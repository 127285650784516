import axios from "axios";
import { createFormData } from "../utils/helper";

export const createReceipt = async (props) => {
    try {
        const response = await axios.post(`${import.meta.env.VITE_API_URL}/users/${props.user.data.id}/receipts`, props.data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${props.user.token}`
              },
        });
        return response.data.data;
    } catch (error) {
        if(error.response) {
            throw new Error(error.response.data.message);
        }
        throw new Error(error.message);
    }
}

export const getReceipts = async (props) => {
    try {
        const response = await axios.get(`${import.meta.env.VITE_API_URL}/users/${props.data.id}/receipts`, {
            headers: {
                Authorization: `Bearer ${props.token}`
            }
        });
        return response.data.data;
    } catch (error) {
        if(error.response) {
            throw new Error(error.response.data.message);
        }
        throw new Error(error.message);
    }
}

export const searchReceipts = async (props) => {  
}

export const deleteReceipt = async (props) => {
    try {
        const response = await axios.delete(`${import.meta.env.VITE_API_URL}/users/receipts/${props.id}`, {
            headers: {
                Authorization: `Bearer ${props.user.token}`
            }
        });
        return response.data.data;
    } catch (error) {
        if(error.response) {
            throw new Error(error.response.data.message);
        }
        throw new Error(error.message);
    }
}

export const updateReceipt = async (props) => {
    try {
        const formData = createFormData(props.data);

        const response = await axios.put(`${import.meta.env.VITE_API_URL}/users/${props.user.data.id}/receipts`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${props.user.token}`
              },
        });

        return response.data.data;
    } catch (error) {
        if(error.response) {
            throw new Error(error.response.data.message);
        }
        throw new Error(error.message);
    }
}

export const getReceipt = async (props) => {
    try {
        const response = await axios.get(`${import.meta.env.VITE_API_URL}/users/receipts/${props.id}`, {
            headers: {
                Authorization: `Bearer ${props.user.token}`
            }
        });
        return response.data.data;
    } catch (error) {
        if(error.response) {
            throw new Error(error.response.data.message);
        }
        throw new Error(error.message);
    }
}
import React from "react";
import "./InvoiceSummary.css";
import InvoiceCheckbox from "./Components/InvoiceCheckbox/InvoiceCheckbox";
import Barcode from "react-barcode";
import InvoiceInput from "./Components/InvoiceInput/InvoiceInput";
import { formatCurrency } from "../../../utils/helper";

const InvoiceSummary = ({data, id}) => {
    return (
        <div className="invoice_summary_container">
            <div className="invoice_summary_header">
                <div className="invoice_summary_payment">
                    <InvoiceCheckbox checked={data?.cash} title={"Cash"}/>
                    <InvoiceCheckbox checked={data?.charge} title={"Charge"}/>
                    <InvoiceCheckbox checked={data?.check} title={"Check"}/>
                </div>
                <h2>TOTAL SUMMARY</h2>
            </div>
            <div className="invoice_summary_body">
                <div className="invoice_summary_barcode">
                    <Barcode margin={5} className="middle" displayValue={false} fontSize={10} value={id}/>
                </div>
                <div className="invoice_summary_details">
                    <div className="invoice_summary_details_row">
                        <div className="invoice_summary_details_row_div">
                            <p>Total Labor</p>
                        </div>
                        <InvoiceInput value={formatCurrency(data?.labor)}/>
                    </div>
                    <div className="invoice_summary_details_row">
                        <div className="invoice_summary_details_row_div">
                            <p>Total Parts</p>
                        </div>
                        <InvoiceInput value={formatCurrency(data?.parts)}/>
                    </div>
                    <div className="invoice_summary_details_row">
                        <div className="invoice_summary_details_row_div">
                            <p></p>
                        </div>
                        <InvoiceInput/>
                    </div>
                    <div className="invoice_summary_details_row">
                        <div className="invoice_summary_details_row_div">
                            <p>Total</p>
                        </div>
                        <InvoiceInput value={formatCurrency(data?.total)}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InvoiceSummary;
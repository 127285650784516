import React, { useEffect } from "react";
import "./ReusableForm.css";
import FileUpload from "../FileUpload/FileUpload"; // Adjust the path as necessary
import Input from "../Input/Input";
import Button from "../Button/Button";
import moment from "moment";
import { useForm } from "react-hook-form";
import TextArea from "../TextArea/TextArea";

const ReusableForm = ({ fields, isPending, onSubmit, defaultValues = {} }) => {
    const {
        register,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const formatDate = (date) => {
        if (!date) return '';
        return moment(date, "MM-DD-YYYY").format("YYYY-MM-DD");
    };

    useEffect(() => {
        Object.keys(defaultValues).forEach((key) => {
            let value = defaultValues[key];
            if (key === 'date') {
                value = formatDate(value); // Format the date value using moment
            }
            if( key.includes('Documents')) {
                value = value = value.map((val) => val.name);
            }
            setValue(key, value);
        });
    }, [defaultValues, setValue]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {fields.map((field, index) => {
                if (field.group) {
                    const groupFields = fields.filter(f => f.group === field.group);
                    fields = fields.filter(f => f.group !== field.group);

                    return (
                        <div key={index} className="reusableForm_inputgroup">
                            {groupFields.map((groupField, groupIndex) => (
                                <Input
                                    key={groupIndex}
                                    type={groupField.type}
                                    name={groupField.label}
                                    error={errors[groupField.name] && errors[groupField.name].message}
                                    placeholder={groupField.placeholder}
                                    register={register(groupField.name, { required: groupField.error })}
                                />
                            ))}
                        </div>
                    );
                }

                return (
                    <React.Fragment key={index}>
                        {field.type === "file" ? (
                            <FileUpload
                                setValue={setValue}
                                name={field.name}
                                defaultFiles={defaultValues[field.name] || []}
                            />
                        ) : field.type === "textarea" ? (
                            <TextArea
                                name={field.label}
                                error={errors[field.name] && errors[field.name].message}
                                register={register(field.name, { required: field.error })}
                                placeholder={field.placeholder}
                            />
                        ) : (
                            <Input
                                name={field.label}
                                type={field.type}
                                error={errors[field.name] && errors[field.name].message}
                                register={register(field.name, { required: field.error })}
                                placeholder={field.placeholder}
                            />
                        )}
                    </React.Fragment>
                );
            })}
            <Button title={"Submit"} loading={isPending} type="submit" className="submit_button" />
        </form>
    );
};

export default ReusableForm;

import "../assets/styles/shared.css";
import { Fragment, useEffect, useState } from "react";
import React from "react";
import Table from "../components/Table/Table";
import Pagination from "../components/Pagination/Pagination";
import Layout from "../components/Layout/Layout";
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { deleteWarranty, getWarranties, searchWarranties } from "../services/warrantyService";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import LoadingSpinner from "../components/LoadingSpinner/LoadingSpinner";
import { useMutation, useQuery } from "@tanstack/react-query";
import Button from "../components/Button/Button";
import Toast from "../components/Toast/Toast";
import Error from "../components/Error/Error";
import Modal from "../components/Modal/Modal";
import * as Sentry from "@sentry/react";

const Warranties = () => {
    const user = useSelector((state) => state.auth.user);
    const [warrantiesToDisplay, setWarrantiesToDisplay] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [entriesPerPage, setEntriesPerPage] = useState(25);
    const [totalPages, setTotalPages] = useState(0);
    const [totalValues, setTotalValues] = useState(0);
    const [searchResults, setSearchResults] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [showToast, setShowToast] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [warrantyToDelete, setWarrantyToDelete] = useState(null);

    const navigate = useNavigate();
    const mutation = useMutation({mutationFn: deleteWarranty});


    const { isLoading, isError, error, data: allWarranties, refetch } = useQuery({
        queryKey: ["warranties", user.data.id],
        queryFn: () => getWarranties(user),
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if (allWarranties) {
            const results = searchValue ? searchResults : allWarranties;
            const { warranties, totalPages, totalValues } = getWarrantiesToDisplay(results);
            setWarrantiesToDisplay(warranties);
            setTotalPages(totalPages);
            setTotalValues(totalValues);
        }
    }, [allWarranties, searchResults, searchValue, entriesPerPage, currentPage]);

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    const getWarrantiesToDisplay = (warrantiesList) => {
        const totalPages = Math.ceil(warrantiesList.length / entriesPerPage);
        const totalValues = warrantiesList.length;
        const start = (currentPage - 1) * entriesPerPage;
        const end = start + entriesPerPage;
        const warranties = warrantiesList.slice(start, end);
        return { warranties, totalPages, totalValues };
    };

    const handleDelete = () => {
        setShowDeleteModal(!showDeleteModal);
        mutation.mutate(({id: warrantyToDelete.id, user}), {
            onSuccess: () => {
                setWarrantyToDelete(null);
                refetch();
            },
            onError: (error) => {
                Sentry.captureException(error);
            }
        });
    }

    const handleView = (warranty) => {
        window.open(warranty.document, '_blank', 'noopener,noreferrer');
    }

    const handlePressDelete = (warranty) => {
        setWarrantyToDelete(warranty);
        setShowDeleteModal(!showDeleteModal);
    }

    const handleCancel = () => {
        setShowDeleteModal(!showDeleteModal);
    }

    const handleSearch = async (searchValue) => {
        setSearchValue(searchValue);
        if (searchValue === "") {
            setSearchResults([]);
        } else {
            const results = await searchWarranties({user, fields: ["car", "part", "partNumber"], query: searchValue});
            setSearchResults(results);
        }
        setCurrentPage(1);
    };

    const headers = {
        main: [
            { label: "Car", name: "car"},
            { label: "Part", name: "part"},
            { label: "Part Number", name: "partNumber" },
            { label: "Warranty Period", name: "warrantyPeriod" },
            { label: "Store", name: "store" },
            { label: "Date", name: "date" },
            { label: "Actions", name: "actions", actions: ["edit", "delete"]}
        ],
        sub: [
            { label: "Name", name: "name"},
            { label: "Actions", name: "actions", actions: ["view"]}
        ]
    };

    const onSearch = async (e) => {
        const searchValue = e.target.value;
        await handleSearch(searchValue);
    }

    const navigateToCreateWarranties = () => {
        navigate("/warranties/add");
    };

    const handleEdit = (warranty) => {
        navigate(`/warranties/edit/${warranty.id}`);
    };


    const deleteModal = () => {
        return (
            <Modal show={showDeleteModal} onClose={handleCancel}>
                <div className="shared_delete_modal_container">
                    <div>
                        <div className="shared_delete_icon_container">
                            <WarningAmberOutlinedIcon className="shared_delete_icon" style={{fontSize: "5rem" }} />
                        </div>

                        <h3 style={{textAlign: 'center'}}>Are you sure?</h3>
                        <p>This action cannot be undone! This will permenanetly delete<br/> <b>{warrantyToDelete.car}</b> and any data related to it</p>
                        <div className="shared_delete_modal">
                            <Button title="Cancel" onClick={handleCancel} style={{backgroundColor: "white", color: "black", border: "1px solid grey"}} type="button" />
                            <Button title="Delete" onClick={handleDelete} style={{backgroundColor: "#FF6F6F"}} type="button" />
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
    
    if (isLoading) {
        return (
            <Layout activeLink={"warranties"}>
                <div className="shared_title_content">
                    <h3 className="shared_title">Warranties</h3>
                </div>
                <div className="shared_loading_spinner_container">
                    <LoadingSpinner color="grey" size="32px" />
                </div>
            </Layout>
        );
    }

    if (isError) {
        Sentry.captureException(error);
        return (
            <Layout activeLink={"warranties"}>
                <div className="shared_title_content">
                    <h3 className="shared_title">Warranties</h3>
                </div>
                <Error/>
            </Layout>
        );
    }
    return (
        <Layout activeLink={"warranties"} searchBarOnChange={onSearch}>
            <div className="shared_title_content">
                <h3 className="shared_title">Warranties</h3>
                {showToast &&
                    <Toast title="Success" text="Warranty created successfully" type="success" />
                }   
                <div className="shared_add_button">
                    <Button title="Create Warranty" onClick={navigateToCreateWarranties} type="button" />
                </div>
            </div>
            {showDeleteModal && deleteModal()}
            <Fragment>
                <div className="shared_table">
                    <Table headers={headers} onDelete={handlePressDelete} onView={handleView} onEdit={handleEdit} data={warrantiesToDisplay} />
                </div>
                {totalValues > 0 &&(
                    <div className="shared_pagination">
                        <p className="shared_total">
                            Showing {entriesPerPage * (currentPage - 1) + 1} to{" "}
                            {Math.min(entriesPerPage * currentPage, totalValues)} of {totalValues} entries
                        </p>
                        <Pagination totalPages={totalPages} pCurrentPage={currentPage} onPageChange={onPageChange} />
                    </div>
                )}
            </Fragment>
        </Layout>
    )
}

export default Warranties;

import React, { useState } from "react";
import "../assets/styles/shared.css";
import "../assets/styles/settings.css";
import Layout from "../components/Layout/Layout";
import Tabbar from "../components/Tabbar/Tabbar";
import SettingItem from "../components/SettingItem/SettingItem";
import { useDispatch, useSelector } from "react-redux";
import SettingProfilePictureItem from "../components/SettingItem/SettingProfilePictureItem";
import Button from "../components/Button/Button";
import { useForm } from "react-hook-form";
import { updateUser } from "../services/userService";
import { useMutation } from "@tanstack/react-query";
import Toast from "../components/Toast/Toast";
import { updateState } from "../reducers/authReducer";

const Settings = () => {
    const user = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();

    const {
        register,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            profilePicture: user?.data.profilePicture,
            fullName: user?.data.fullName,
            username: user?.data.username,
            email: user?.data.email,
            role: user?.data.role,
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
        }
    });
    const mutation = useMutation({mutationFn: updateUser});

   // user.data.profilePicture = "https://gudinosrepair.s3.us-west-1.amazonaws.com/dev/Default_pfp.jpg";

    const links = [
        {name: "Account", link: "/settings/account", items: [
            <>
                <SettingProfilePictureItem title="Profile Picture" profilePicture={user?.data.profilePicture} setValue={setValue} />
                <SettingItem title="Full Name" register={register("fullName")} />
                <SettingItem title="Username" value={user?.data.username} register={register("username")} />
                <SettingItem title="Email" value={user?.data.email} register={register("email")} />
                <SettingItem disabled={true} title="Role" register={register("role")} />
            </>
        ]},
        {name: "Security", link: "/settings/security", items: [
        <div className="settings_content">
        <h1>Password</h1>
        <p>Please enter your current password and your new password</p>
            <SettingItem title="Current Password" type="password" register={register("currentPassword")} />
            <SettingItem title="New Password" type="password" register={register("newPassword")} />
            <SettingItem title="Confirm New Password" type="password" register={register("confirmPassword")} />
        </div>
        ]},
    ];

    const [active, setActive] = useState(links[0]);
    const [showToast, setShowToast] = useState(false);
    const [toast, setToast] = useState({type: "", title: "", text: ""});

    const handleTabClick = (name) => {
        setActive(name);
    }

    const onSubmit = (data) => {
        mutation.mutate(({data: data, user: user}), {
            onSuccess: (data) => {
                dispatch(updateState({data: data, token: user.token}));
                setToast({type: "success", title: "Success", text: "User updated successfully"});
                setShowToast(true);
                setValue("currentPassword", "");
                setValue("newPassword", "");
                setValue("confirmPassword", "");
            },
            onError: (error) => {
                setToast({type: "error", title: "Error", text: error.message});
                setShowToast(true);
            }
        });
    }

    const handleToastClose = () => {
        setShowToast(false);
    };

    return (
        <Layout activeLink={"settings"}>
            <div className="shared_title_content noborder">
                <h3 className="shared_title">Settings</h3>
                <div className="shared_add_button">
                    <Button title="Save Changes" loading={mutation.isPending} type="button" onClick={handleSubmit(onSubmit)} />
                </div>
            </div>
            <Tabbar links={links} onClick={handleTabClick} active={active.name} />
            <div className="settings_container">
            {showToast && <Toast title={toast.title} text={toast.text} type={toast.type} onClose={handleToastClose}/>}
                <form>
                {active.items}
                </form>
            </div>
        </Layout>
    )
}

export default Settings;
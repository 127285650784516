import React, { useEffect, useRef, useState } from "react";
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import "./Dropdown.css";

const Dropdown = ({options, defaultVal, onChange }) => {

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [selectedOption, setSelectedOption] = useState(defaultVal);
    const dropdownRef = useRef(null);

    useEffect(() => {
        if (dropdownRef.current) {
            dropdownRef.current.scrollTop = scrollPosition;
        }
    }, [isDropdownOpen, scrollPosition]);

    const handleDropdownOpen = () => {
        setIsDropdownOpen(!isDropdownOpen);
    }

    const handleOnClick = (option) => {

        setSelectedOption(option);
        setIsDropdownOpen(false);
        if (dropdownRef.current) {
            setScrollPosition(dropdownRef.current.scrollTop);
          }
        onChange(option);
    }

    return (
        <div className="dropdown_container">
            <div className="dropdown_text" onClick={handleDropdownOpen}>
                <p>{selectedOption}</p>
                <ExpandMoreOutlinedIcon className="dropdown_icon"/>
            </div>
            
            {isDropdownOpen &&
            <div ref={dropdownRef} className={"dropdown_options"}>
                {isDropdownOpen && options.map((option) => (
                    <div key={option} className={`dropdown_option ${selectedOption == option ? "dropdown_active" : ""}`} onClick={() => handleOnClick(option)}>
                        <p>{option}</p>
                    </div>
                ))}
            </div>
            }
        </div>
    );
};

export default Dropdown;

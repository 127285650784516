import React from "react";
import "./BackIcon.css";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useNavigate } from "react-router-dom";

const BackIcon = () => {
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    }
    return (
        <div className="backicon_container" onClick={goBack}>
            <ArrowBackOutlinedIcon/>
        </div>
    )
}

export default BackIcon;
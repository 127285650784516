import React from "react";
import "./InvoiceRepair.css";
import InvoiceCheckbox from "./Components/InvoiceCheckbox/InvoiceCheckbox";
import InvoiceInput from "./Components/InvoiceInput/InvoiceInput";
import { formatCurrency } from "../../../utils/helper";

const InvoiceRepair = ({data}) => {
    return (
        <div className="invoice_repair_container">
            <div className="invoice_repairs_header">
                <h2>DESCRIPTION OF REPAIRS</h2>
                <h2>AMOUNT</h2>
            </div>
            <div className="invoice_repairs_body">
                {Array.from({length: 20}).map((_, index) => (
                    <div className="invoice_repairs_body_row" key={index}>
                        <InvoiceInput value={data?.[index]?.service} inputClassName={index % 2 == 0 ? "invoice_repairs_borderless" : "invoice_repairs_borderless_striped"} className={"flex5"}/>
                        <InvoiceInput value={formatCurrency(data?.[index]?.price)} inputClassName={index % 2 == 0 ? "invoice_repairs_borderless" : "invoice_repairs_borderless_striped"}/>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default InvoiceRepair;
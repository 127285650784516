import React from "react";
import "./Sidebar.css";
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Sidebar = ({sidebarOpen, activeLink}) => {
    const user = useSelector(state => state.auth.user);
    console.log(user);
    return (
        <nav className={`sidebar ${sidebarOpen ? "open" : ""}`}>
            <div className="sidebarLogo">
            </div>
            <ul className={`sidebarLinks`}>
                <li>
                    <Link to={"/dashboard"} className={`nav ${activeLink === 'dashboard' ? 'active' : ''}`}>
                        <HomeOutlinedIcon/>
                        <span>Dashboard</span>
                    </Link>
                </li>
                <li>
                    <Link to={"/jobs"} className={`nav ${activeLink === 'jobs' ? 'active' : ''}`}>
                        <WorkOutlineIcon/>
                        <span>Jobs</span>
                    </Link>
                </li>
                <li>
                    <Link to={"/warranties"} className={`nav ${activeLink === 'warranties' ? 'active' : ''}`}>
                        <ContentPasteOutlinedIcon/>
                        <span>Warranties</span>
                    </Link>
                </li>
                <li>
                    <Link to={"/expenses"} className={`nav ${activeLink === 'expenses' ? 'active' : ''}`}>
                        <AttachMoneyOutlinedIcon/>
                        <span>Expenses</span>
                    </Link>
                </li>
                <li>
                    <Link to={"/receipts"} className={`nav ${activeLink === 'receipts' ? 'active' : ''}`}>
                        <ReceiptLongOutlinedIcon/>
                        <span>Receipts</span>
                    </Link>
                </li>
                <li>
                    <Link to={"/invoices"} className={`nav ${activeLink === 'invoices' ? 'active' : ''}`}>
                        <DescriptionOutlinedIcon/>
                        <span>Invoices</span>
                    </Link>
                </li>
                <hr className="navBreak"/>
                {user?.data?.role === 'ADMIN' &&
                <li>
                    <Link to={"/admin"} className={`nav ${activeLink === 'admin' ? 'active' : ''}`}>
                        <AdminPanelSettingsOutlinedIcon/>
                        <span>Admin</span>
                    </Link>
                </li>
                }
                <li>
                    <Link to={"/settings"} className={`nav ${activeLink === 'settings' ? 'active' : ''}`}>
                        <SettingsOutlinedIcon/>
                        <span>Settings</span>
                    </Link>
                </li>
                <li>
                    <Link to={"/logout"} className={`nav`}>
                        <LogoutOutlinedIcon/>
                        <span>Logout</span>
                    </Link>
                </li>
            </ul>
        </nav>
    )
}

export default Sidebar;

import "./TextArea.css";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import React from 'react';

const TextArea = React.forwardRef(({ placeholder, icon, className, value, maxLength, name, error, onChange, onClick, register }, ref) => {
    return (
        <div className={`input_container ${className}`}>
            <p className="input_name">{name}</p>
            <div className="input_body">
                <span className="icon">{icon}</span>
                <textarea
                    className={`customInput ${error ? 'input_error' : ''}`}
                    placeholder={placeholder}
                    value={value}
                    rows={5}
                    maxLength={maxLength}
                    onChange={onChange}
                    onClick={onClick}
                    ref={ref}
                    {...(register ? register : {})}
                />
            </div>
            {error &&
            <div className="input_error_body">
                <WarningAmberIcon fontSize="small"/>
                <p className="input_error_message">{error}</p>
            </div>
            }
        </div>
    );
});

export default TextArea;


import React from 'react';
import { BrowserRouter as Router, Route, Navigate, Routes } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Login from '../pages/login';
import Dashboard from '../pages/dashboard';
import Jobs from '../pages/jobs';
import Warranties from '../pages/warranties';
import Expenses from '../pages/expenses';
import Settings from '../pages/settings';
import NotFound from '../pages/notfound';
import FormScreen from '../pages/formScreen';
import Invoices from '../pages/invoices';
import Receipts from '../pages/receipts';
import AddInvoices from '../pages/addInvoices';
import Logout from '../pages/logout';


const AppRouter = () => {
    return (
        <Router>
            <Routes>
                <Route path="/login" element={<Login/>} />
                <Route element={<PrivateRoute/>}>
                    <Route exact path="/" render={() => <Navigate to="/dashboard" />} />
                    <Route path="/dashboard" element={<Dashboard/>} />
                    <Route path="/jobs" element={<Jobs/>} />
                    <Route path="/warranties" element={<Warranties/>} />
                    <Route path="/expenses" element={<Expenses/>} />
                    <Route path="/settings" element={<Settings/>} />
                    <Route path="/jobs/add" element={<FormScreen/>} />
                    <Route path='/warranties/add' element={<FormScreen/>} />
                    <Route path='jobs/edit/:id' element={<FormScreen/>} />
                    <Route path='/warranties/edit/:id' element={<FormScreen/>} />
                    <Route path='/receipts' element={<Receipts/>} />
                    <Route path='/receipts/add' element={<FormScreen/>} />
                    <Route path ='/receipts/edit/:id' element={<FormScreen/>} />
                    <Route path='/invoices/edit/:id' element={<AddInvoices/>} />
                    <Route path='/invoices/add' element={<AddInvoices/>} />
                    <Route path='/invoices' element={<Invoices/>} />
                    <Route path='/logout' element={<Logout/>} />
                </Route>
                <Route element={<NotFound/>} /> {/* Catch-all route for 404 errors */}
            </Routes>
        </Router>
    );
}

export default AppRouter;
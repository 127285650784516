import React, { useState } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import "./Navbar.css";
import SearchBar from "../SearchBar/SearchBar";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Navbar = ({toggleSidebar, searchBarOnChange, hideSearchBar = false}) => {
    const {data} = useSelector(state => state.auth.user);
    const options = ["Settings", "Logout"];
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const navigate = useNavigate();

    const handleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    }

    const handleOnClick = (option) => {
        if (option === "Logout") {
            navigate("/logout");
        }

        if (option === "Settings") {
            navigate("/settings");
        }
    }

    return (
        <div className="navbar_container">
            <MenuIcon className="navbar_menu_icon" onClick={toggleSidebar} color="#212121" fontSize="large"/>
            {!hideSearchBar && <SearchBar onChange={searchBarOnChange}/>}
            <div className="navbar_profile" onClick={handleDropdown}>
                <div className="navbar_profile_flex">
                    <img src={data?.profilePicture} width={40} height={40} className="navbar_profile_picture" alt="profile"/>
                    <div className="navbar_user_info">
                        <p>{data?.fullName}</p>
                        <p className="navbar_user_role">{data?.role}</p>
                    </div>
                </div>
                {isDropdownOpen &&
                    <div className="navbar_dropdown">
                        {options.map((option) => (
                            <div key={option} className="navbar_dropdown_option" onClick={() => handleOnClick(option)}>
                                <p>{option}</p>
                            </div>
                        ))}
                    </div>
                }
            </div>
        </div>
    )
}

export default Navbar;
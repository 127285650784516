import React, { useEffect, useState } from "react";
import "../assets/styles/expenses.css";
import Layout from "../components/Layout/Layout";
import Dropdown from "../components/Dropdown/Dropdown";
import Card from "../components/Card/Card";
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import MoneyOffOutlinedIcon from '@mui/icons-material/MoneyOffOutlined';
import Table from "../components/Table/Table";
import { useSelector } from "react-redux";
import { getFormattedExpenses } from "../services/expenseService";
import { useQuery } from "@tanstack/react-query";
import LoadingSpinner from "../components/LoadingSpinner/LoadingSpinner";

const Expenses = () => {
    const user = useSelector((state) => state.auth.user);
    const [options, setOptions] = useState([])
    const [selectedOption, setSelectedOption] = useState(null); 

    const { isLoading, isError, error, data: allExpenses} = useQuery({
        queryKey: ["expensesFormatted", user.data.id],
        queryFn: () => getFormattedExpenses(user),
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if (allExpenses) {

            const dates = allExpenses.map(expense => expense.date);
            const obj = allExpenses.find(expense => expense.date === dates[0]);
            setOptions(dates);
            setSelectedOption(obj);
        }
    }, [allExpenses])

    const handleSelect = (option) => {
        const sOption = allExpenses.find(expense => expense.date === option);
        setSelectedOption(sOption);
    }

    const DollarIcon = () => {
        return (
            <div className="expenses_card_dollarIcon">
                <AttachMoneyOutlinedIcon fontSize="large"/>
            </div>
        )
    }

    const ExpenseIcon = () => {
        return (
            <div className="expenses_card_dollarIcon">
                <MoneyOffOutlinedIcon fontSize="large"/>
            </div>
        )
    }

    const headers = {
        main: [
            { label: "Customer", name: "customer" },
            { label: "Car", name: "car" },
            { label: "Job", name: "job" },
            { label: "Price", name: "price" },
            { label: "Expenses", name: "expenses" },
            { label: "Date", name: "date" },
        ]
    };

    if (isLoading || !selectedOption) {
        return (
            <Layout activeLink="expenses">
                <div className="shared_title_content">
                    <h3 className="shared_title">Expenses</h3>
                </div>
                <div className="shared_loading_spinner_container">
                    <LoadingSpinner color="grey" size="32px" />
                </div>
            </Layout>
        );
    }

    if (isError) {
        return (
            <Layout activeLink="expenses">
                <div className="expenses_header">
                    <h3 className="expenses_title">Expenses</h3>
                </div>
                <div className="error_message">
                    <p>Error: {error.message}</p>
                </div>
            </Layout>
        );
    }

    return (
        <Layout activeLink={"expenses"} hideSearchBar={true}>
            <div className="expenses_header">
                <div className="expenses_title">
                    <h3>Weekly Summary</h3>
                </div>
                <Dropdown onChange={handleSelect} defaultVal={selectedOption.date} options={options}/>
            </div>
                <div className="expenses_cards">
                    <Card title={"Expenses"} icon={<ExpenseIcon/>} className="expenses_card">
                        <p className="expenses_card_amount">${selectedOption.expense}</p>
                        <div className="expenses_card_text">
                            <div className={`${selectedOption.expensePercentage > 0 ? "expenses_card_percentage" : "expenses_card_percentage_negative"}`}>{selectedOption.expensePercentage}%</div>
                            <p>Since Last Week</p>
                        </div>
                    </Card>

                    <Card title={"Income"} icon={<DollarIcon/>} className="expenses_card">
                        <p className="expenses_card_amount">${selectedOption.income}</p>
                        <div className="expenses_card_text">
                            <div className={`${selectedOption.incomePercentage > 0 ? "expenses_card_percentage" : "expenses_card_percentage_negative"}`}>{selectedOption.incomePercentage}%</div>
                            <p>Since Last Week</p>
                        </div>
                    </Card>
                </div>
            <div className="expenses_body">
            <h3 className="expenses_body_header">Jobs This Week</h3>
                <div className="expenses_body_table">
                    <Table headers={headers} data={selectedOption.jobs}/>
                </div>
            </div>
        </Layout>
    )
}

export default Expenses;
import React, { forwardRef, useEffect, useRef, useState } from "react";
import "./Layout.css";
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";

const Layout = forwardRef(({ children, activeLink, hideSearchBar, searchBarOnChange }, ref) => {
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    }

  return (
    <div className="layout_container">
    <Sidebar activeLink={activeLink} sidebarOpen={sidebarOpen}/>
    <div className={`layout_content ${sidebarOpen ? "layout_sidebar" : ""}`}>
        <Navbar hideSearchBar={hideSearchBar} searchBarOnChange={searchBarOnChange} toggleSidebar={toggleSidebar}/>
        <div className="layout_body">
            <div className="layout_body_content" ref={ref}>
                {children}
            </div>
        </div>
    </div>
</div>
  )
})

export default Layout;
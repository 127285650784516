import React from "react";
import "./SearchBar.css";
import { CiSearch } from "react-icons/ci";

const SearchBar = ({ placeholder, value, onChange }) => {
    return (
        <div className="searchBar">
            <input type="text" 
            className="searchInput"
            placeholder={placeholder}
            value={value} 
            onChange={onChange} />
            <CiSearch className="searchIcon"/>
        </div>
    )
}

export default SearchBar;
import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { updateUser } from '../services/userService';

const initialState = {
    user: null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state, action) => {
            state.user = action.payload;
            axios.defaults.headers.common['Authorization'] = `Bearer ${action.payload?.token}`;
        },
        logout: (state) => {
            state.user = null;
        },
        updateState: (state, action) => {
            state.user = action.payload;
        }
    },
});

export const { login, logout, updateState } = authSlice.actions;

export default authSlice.reducer;

import React from 'react';
import "./InvoiceTemplate.css"
import InvoiceContactInfo from './InvoiceContactInfo';
import InvoiceRepair from './InvoiceRepair';
import InvoiceSummary from './InvoiceSummary';

const InvoiceTemplate = ({data, id}) => {
    return (
        <div className='invoice_container'>
            {/* Contact Information Section*/}
            <InvoiceContactInfo id={id} data={data?.ContactInfo}/>

            {/* Description of Repairs */}
            <InvoiceRepair data={data?.RepairInfo}/>

            {/* Summary Information */}
            <InvoiceSummary id={id} data={data?.SummaryInfo}/>
        </div>
    );
}

export default InvoiceTemplate;
import axios from "axios";
import { createFormData } from "../utils/helper";

export const getRepairs = async (props) => {
    try {
        const response = await axios.get(`${import.meta.env.VITE_API_URL}/users/${props.data.id}/repairs`, {
            headers: {
                Authorization: `Bearer ${props.token}`
            }
        });
        return response.data.data;
    } catch (error) {
        if(error.response) {
            throw new Error(error.response.data.message);
        }
        throw new Error(error.message);
    }
}

export const getRepair = async (props) => {
    try {
        const response = await axios.get(`${import.meta.env.VITE_API_URL}/users/repairs/${props.id}`, {
            headers: {
                Authorization: `Bearer ${props.user.token}`
            }
        });
        return response.data.data;
    } catch (error) {
        if(error.response) {
            throw new Error(error.response.data.message);
        }
        throw new Error(error.message);
    }
}

export const createRepair = async (props) => {
    try {
     
      const formData = createFormData(props.data);

      const response = await axios.post(`${import.meta.env.VITE_API_URL}/users/${props.user.data.id}/repairs`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${props.user.token}`,
        },
      });
  
      return response.data.data;
    } catch (error) {
      if (error.response) {
        throw new Error(error.response.data.message);
      }
      throw new Error(error.message);
    }
  };

export const updateRepair = async (props) => {
    try {
        const formData = createFormData(props.data);
        
        const response = await axios.put(`${import.meta.env.VITE_API_URL}/users/${props.user.data.id}/repairs`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${props.user.token}`
              },
        });
        return response.data.data;
    } catch (error) {
        if(error.response) {
            throw new Error(error.response.data.message);
        }
        throw new Error(error.message);
    }
}

export const searchRepairs = async (props) => {
    try {
        const response = await axios.post(`${import.meta.env.VITE_API_URL}/users/${props.user.data.id}/repairs/search`, 
        {
            fields: props.fields,
            query: props.query
        }, {
            headers: {
                Authorization: `Bearer ${props.user.token}`
              },
        });
        return response.data.data;
    } catch (error) {
        if(error.response) {
            throw new Error(error.response.data.message);
        }
        throw new Error(error.message);
    }
}

export const deleteRepair = async (props) => {
    try {
        const response = await axios.delete(`${import.meta.env.VITE_API_URL}/users/repairs/${props.id}`, {
            headers: {
                Authorization: `Bearer ${props.user.token}`
            }
        });
        return response.data.data;
    } catch (error) {
        if(error.response) {
            throw new Error(error.response.data.message);
        }
        throw new Error(error.message);
    }
}
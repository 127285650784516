import React, { useEffect } from 'react';
import AppRouter from './navigation/AppRouter.jsx';
import { validateToken } from './utils/helper.js';

const App = () => {

  validateToken();
  
  return (
    <>
      <AppRouter/>
    </>
  )
}

export default App


import React from 'react';
import {Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = () => {
    let isAuthenticated = useSelector(state => state.auth.user);
    return (
        isAuthenticated ? <Outlet/> : <Navigate to={'/login'}/>
    )
};

export default PrivateRoute;

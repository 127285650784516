import React, { useEffect, useRef, useState } from "react";
import Layout from "../components/Layout/Layout";
import InvoiceTemplate from "./templates/Invoices/InvoiceTemplate";
import "../assets/styles/invoices.css";
import Input from "../components/Input/Input";
import { useForm } from "react-hook-form";
import Button from "../components/Button/Button";
import moment from "moment";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import Checkbox from "../components/Checkbox/Checkbox";
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import BackIcon from "../components/BackIcon/BackIcon";
import { useMutation, useQuery } from "@tanstack/react-query";
import { createInvoice, getNewInvoiceId, getInvoice, updateInvoice } from "../services/invoiceService";
import { useSelector } from "react-redux";
import * as Sentry from "@sentry/react";
import { useNavigate, useParams } from "react-router-dom";
import Toast from "../components/Toast/Toast";
import { addCurrencyValues, formatCurrency } from "../utils/helper";
import { generatePDF } from "../utils/pdf";

const AddInvoices = () => {
    const user = useSelector((state) => state.auth.user);
    const scrollRef = useRef(null);
    const invoiceTemplateRef = useRef();
    const reactToPrintRef = useRef();
    const totalRef = useRef();
    const [id, setId] = useState("1");
    const [page, setPage] = useState(1);
    const [showToast, setShowToast] = useState(false);
    const [loading, setLoading] = useState(false);
    const mutation = useMutation({mutationFn: createInvoice});
    const editMutation = useMutation({mutationFn: updateInvoice});
    const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm();
    const formData = watch();
    const navigate = useNavigate();
    const params = useParams();
    const { isLoading, isError, error, data: invoiceId} = useQuery({
        queryKey: ["invoiceId", user.data.id],
        queryFn: () => getNewInvoiceId(user),
        refetchOnWindowFocus: false,
    }); 
        
    if(params.id) {
        const {data: invoice} = useQuery({
            queryKey: ["invoice", params.id],
            queryFn: () => getInvoice({id: params.id, user}),
            refetchOnWindowFocus: false,
         });

         useEffect(() => {
            populateData();
         }, [invoice]);

         const populateData = async () => {
            if(invoice) {
                const contactInfo = invoice.contactInfo;
                const summaryInfo = invoice.summaryInfo;
                const repairInfo = invoice.repairInfo;
                setValue("ContactInfo.date", moment(contactInfo.date, "MM-DD-YYYY").format("YYYY-MM-DD"));
                setValue("ContactInfo.name", contactInfo.name);
                setValue("ContactInfo.address", contactInfo.address);
                setValue("ContactInfo.citystatezip", contactInfo.cityStateZip);
                setValue("ContactInfo.phone", contactInfo.phone);
                setValue("ContactInfo.yearmakemodel", contactInfo.yearMakeModel);
                setValue("ContactInfo.vin", contactInfo.vin);
                setValue("ContactInfo.licenseNo", contactInfo.licenseNo);
                setValue("ContactInfo.odometer", contactInfo.odometer);
                setValue("SummaryInfo.cash", summaryInfo.paymentType == "cash");
                setValue("SummaryInfo.charge", summaryInfo.paymentType == "charge");
                setValue("SummaryInfo.check", summaryInfo.paymentType == "check");
                setValue("SummaryInfo.labor", formatCurrency(summaryInfo.labor));
                setValue("SummaryInfo.parts", formatCurrency(summaryInfo.parts));
                setValue("SummaryInfo.total", formatCurrency(summaryInfo.total));
                repairInfo.forEach((repair, index) => {
                    setValue(`RepairInfo[${index}].service`, repair.service);
                    setValue(`RepairInfo[${index}].price`, formatCurrency(repair.price));
                });

                setId(invoice.id);
            }
         };
    }

    if(!params.id) {
        useEffect(() => {
            setId(invoiceId);
        }, [invoiceId]);
    }

    const formPage = (page) => {
        switch (page) {
            case 1:
                return (
                    <>
                        <Input register={register("ContactInfo.date", {required: true})} 
                        error={errors["ContactInfo.date"] && errors["ContactInfo.date"].message} type="date" name="Date" />
                        <h2>Contact Information</h2>
                        <Input register={register("ContactInfo.name")} name="Name" />
                        <Input register={register("ContactInfo.address")} name="Address" />
                        <Input register={register("ContactInfo.citystatezip")} name="City, State, ZIP" />
                        <Input register={register("ContactInfo.phone")} name="Phone" />
                        <h2>Vehicle Information</h2>
                        <Input register={register("ContactInfo.yearmakemodel")} name="Year, Make, Model" />
                        <Input register={register("ContactInfo.vin")} maxLength="17" name="VIN" />
                        <Input register={register("ContactInfo.licenseNo")} name="License No." />
                        <Input register={register("ContactInfo.odometer")} name="Odometer Reading" />
                    </>
                );
            case 2:
                return (
                    <>
                        <h2>Repair Information</h2>
                        {Array.from({length: 20}).map((_, index) => (
                            <div>
                                <h3>Line {index + 1}</h3>
                                <div className="page_invoice_body_row" key={index}>
                                    <Input register={register(`RepairInfo[${index}].service`)} name="Service" />
                                    <Input register={register(`RepairInfo[${index}].price`)} name="Price" />
                                </div>
                            </div>
                        ))}
                    </>
                );
            case 3:
                return (
                    <>
                        <h2>Summary Information</h2>
                        <div className="page_invoice_payments">
                            <Checkbox register={register("SummaryInfo.cash")} label={"Cash"}/>
                            <Checkbox register={register("SummaryInfo.charge")} label={"Charge"}/>
                            <Checkbox register={register("SummaryInfo.check")} label={"Check"}/>
                        </div>
                        <Input register={register("SummaryInfo.labor")} name="Total Labor" />
                        <Input register={register("SummaryInfo.parts")} name="Total Parts" />
                        <Input register={register("SummaryInfo.total")} onClick={handleInputClick} name="Total" />
                    </>
                );
            default:
                return null;
        }
    };

    useEffect(() => {
        scrollRef.current.scrollTop = 0;
    }, [page]);

    const handlePrint = useReactToPrint({
        onAfterPrint: () => {
            setLoading(false);
            navigate(-1);
        },
        content: () => invoiceTemplateRef.current,
    });

    const onSubmit = async (data) => {
        setLoading(true);
        const pdf = await generatePDF(invoiceTemplateRef.current, id);
        if(params.id) {
            editMutation.mutate({data, id: params.id, pdf: pdf, user}, {
                onSuccess: () => {
                    handlePrint();
                },
                onError: (error) => {
                    scrollRef.current.scrollTop = 0
                    setShowToast(true);
                    setLoading(false);
                    Sentry.captureException(error);
                },
            });
        } else {
            mutation.mutate({data, id, pdf: pdf, user}, {
                onSuccess: () => {
                    handlePrint();
                },
                onError: (error) => {
                    scrollRef.current.scrollTop = 0
                    setShowToast(true);
                    setLoading(false);
                    Sentry.captureException(error);
                }
            });
        }
    }

    const handleToastClose = () => {
        setShowToast(false);
    };

    const handleNext = () => {
        setPage((prevPage) => prevPage + 1);
    };

    const handlePrevious = () => {
        setPage((prevPage) => prevPage - 1);
    };

    const handleInputClick = (e) => {
        let labor = formData?.SummaryInfo?.labor;
        let parts = formData?.SummaryInfo?.parts;
        const total = addCurrencyValues(labor, parts);
    
        setValue("SummaryInfo.total", total);

        e.currentTarget.value = total; // Format to two decimal places
    };

    return (
        <Layout hideSearchBar={true} ref={scrollRef}>
            <div className="formScreen_header">
                <BackIcon/>
            </div>
            <div className="page_invoice_container">
                {showToast && <Toast title="Error" text="There was an error submitting the invoice" type="error" onClose={handleToastClose}/>}
                <div className="page_invoice_form">
                    <form key={page} onSubmit={handleSubmit(onSubmit)}>
                        {formPage(page)}
                        <div className="page_invoice_buttons">
                            {page !== 1 && <Button type="button" title="Previous" onClick={handlePrevious} />}
                            {page !== 3 && <Button type="button" title="Next" onClick={handleNext} />}
                            {page === 3 && (
                                <Button type="submit" loading={loading} title={params.id ? "Edit" : "Submit"}/>
                            )}
                        </div>
                    </form>
                </div>
                <div className="page_invoice_template_container">
                    <div className="page_invoice_template">
                        <div className="page_invoice_header">
                            <p className="page_invoice_preview">Preview</p>
                            <ReactToPrint
                                trigger={() => <PrintOutlinedIcon className="page_invoice_print"/>}
                                content={() => invoiceTemplateRef.current}
                                ref={reactToPrintRef}
                            />
                        </div>
                        <div className="page_invoice_scale" ref={invoiceTemplateRef}>
                            <InvoiceTemplate data={formData} id={id} />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};


export default AddInvoices;

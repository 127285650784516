import React, { useState } from 'react';
import "../assets/styles/login.css";
import Input from '../components/Input/Input.jsx'
import Button from '../components/Button/Button.jsx';
import { CiLock, CiUser } from "react-icons/ci";
import { useDispatch } from 'react-redux';
import { loginUser } from '../services/loginService.js';
import { useMutation } from '@tanstack/react-query';
import { login } from '../reducers/authReducer.js';
import { useNavigate } from 'react-router-dom';


const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const mutation = useMutation({
        mutationFn: loginUser,
    })

    const handleLogin = (event) => {
        event.preventDefault();
        mutation.mutate({username, password},
            {
                onSuccess: (data) => {
                    dispatch(login({data: data.data, token: data.token}));
                    navigate('/dashboard');
                },
            }
        );
    }


    return (
        <div className='loginContainer'>
            <form className='login section' onSubmit={handleLogin}>
                    <div className='heading'>
                        <h1 className='header'>Log in to your Account</h1>
                        <p className='subHeader'>Enter your username and password to continue.</p>
                    </div>
                    {mutation.error && <p className='error'>{mutation.error.message}</p>}
                    <Input value={username} icon={<CiUser/>} error={mutation.isError} placeholder={"Username"} onChange={(e) => setUsername(e.target.value)}/>
                    <Input value={password} icon={<CiLock/>} error={mutation.isError} type={"password"} placeholder={"Password"} onChange={(e) => setPassword(e.target.value)}/>
                    <a className='forgotPassword' href="#">Forgot your password?</a>
                    <Button title="Log in" loading={mutation.isPending} type="submit"/>
                    <div className='spacer'></div>
                    <p className='noAccount'>Don't have an account? Contact an Administrator!</p>
            </form>
            <div className='info section'>

            </div>
        </div>
    )
}

export default Login;
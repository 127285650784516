import CloudQueueOutlinedIcon from '@mui/icons-material/CloudQueueOutlined';
import Button from '../Button/Button';
import "./Error.css";

const Error = () => {
    return (
        <div className='error_component_container'>
            <CloudQueueOutlinedIcon style={{fontSize: "5rem"}}/>
            <p className='error_component_text'>Oops... It looks like something went wrong</p>
        </div>
    )
}

export default Error;
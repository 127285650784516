import React from 'react';
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, AreaChart, Area } from 'recharts';

const CustomAreaChart = ({data}) => {
    return (
      <ResponsiveContainer width={"100%"} height={400}>
        <AreaChart
          data={data}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#2D6BDF" stopOpacity={0.8}/>
              <stop offset="95%" stopColor="#2D6BDF" stopOpacity={0}/>
            </linearGradient>
            </defs>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="label" />
          <YAxis
          
          />
          <Tooltip />
          <Area type="monotone" dataKey="income" stroke="#2D6BDF" fillOpacity={1} fill="url(#colorUv)" activeDot={{ r: 8 }} />
        </AreaChart>
      </ResponsiveContainer>
    );
}

export default CustomAreaChart;

import React from "react";
import "./Card.css";

const Card = ({ title, icon, children }) => {
    return (
        <div className="card_container">
            <div className="card_header">
            <p className="card_title">{title}</p>
            {icon}
            </div>
            {children}
        </div>
    );
}

export default Card;
import React from "react"
import './InvoiceContactInfo.css'
import GudinosLogo from "../../../assets/images/Gudinoslogo.jpg"
import InvoiceInput from "./Components/InvoiceInput/InvoiceInput"

const InvoiceContactInfo = ({data, id}) => {
    return (
        <div className='invoice_contact_container'>
            <div className='invoice_contact_logo'>
                <div className="invoice_contact_logo_image">
                    <img src={GudinosLogo} alt="Gudinos Logo" width={300} height={150}/>
                </div>
                <div className="invoice_contact_vin_container">
                    {Array(17).fill(0).map((_, index) => {
                        return (
                            <div key={index} className="invoice_contact_vin_identifier_container">
                                <label className="invoice_contact_vin_identifier">{index+1}</label>
                                <label className="invoice_contact_vin_number">{data?.vin?.charAt(index)}</label>
                            </div>
                        )
                    })
                    }
                </div>
            </div>
        <div className='invoice_contact_info'>
            <h1>INVOICE</h1>
            <div className='invoice_contact_invoice_info'>
                <div>
                    <h2>Date</h2>
                    <input type='text' className="invoice_contact_input" value={data?.date} disabled/>
                </div>
                <div>
                    <h3>No.</h3>
                    <input type='text' className="invoice_contact_input" value={id} disabled/>
                </div>
            </div>
           <InvoiceInput value={data?.name} label={"Name"}/>
           <InvoiceInput value={data?.address} label={"Address"}/>
           <InvoiceInput value={data?.citystatezip} label={"City,State,ZIP"}/>
           <InvoiceInput value={data?.phone} label={"Phone"}/>
           <InvoiceInput value={data?.yearmakemodel} label={"Year,Make,Model"}/>
            <div className="invoice_contact_car_info">
                <label className="invoice_contact_vin">VIN</label>
                <InvoiceInput value={data?.licenseNo} label={"License NO."}/>
                <InvoiceInput value={data?.odometer} label={"Odometer"}/>
            </div>
        </div>
    </div>

    )
}

export default InvoiceContactInfo
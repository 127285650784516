import { useDispatch } from "react-redux";
import { logout } from "../reducers/authReducer";
 
const Logout = () => {
    const dispatch = useDispatch();
    try {
        dispatch(logout());
    } catch (error) {
        console.error(error);
    }
}

export default Logout;
import React, { useState } from 'react';
import './Pagination.css';

const Pagination = ({ totalPages, pCurrentPage, onPageChange }) => {
    const [currentPage, setCurrentPage] = useState(pCurrentPage);

    const getPageNumbers = () => {
        const pageNumbers = [];
        const maxPageNumbersToShow = 5;
        const halfRange = Math.floor(maxPageNumbersToShow / 2);
        let startPage, endPage;

        if (totalPages <= maxPageNumbersToShow) {
            startPage = 1;
            endPage = totalPages;
        } else if (currentPage <= halfRange) {
            startPage = 1;
            endPage = maxPageNumbersToShow;
        } else if (currentPage + halfRange >= totalPages) {
            startPage = totalPages - maxPageNumbersToShow + 1;
            endPage = totalPages;
        } else {
            startPage = currentPage - halfRange;
            endPage = currentPage + halfRange;
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        return pageNumbers;
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        onPageChange(pageNumber);
    };

    const handleFirst = () => {
        if (currentPage > 1) {
            handlePageChange(1);
        }
    };

    const handleLast = () => {
        if (currentPage < totalPages) {
            handlePageChange(totalPages);
        }
    };

    return (
        <div className='pagination-container'>
            <div className="pagination">
                <button 
                    onClick={handleFirst} 
                    disabled={currentPage === 1}
                    className="pagination-button"
                >
                    &laquo;
                </button>
                {getPageNumbers().map(pageNumber => (
                    <button 
                        key={pageNumber} 
                        onClick={() => handlePageChange(pageNumber)} 
                        className={`pagination-button ${currentPage === pageNumber ? 'active' : ''}`}
                    >
                        {pageNumber}
                    </button>
                ))}
                {totalPages > getPageNumbers().slice(-1)[0] && (
                    <span className="pagination-dots">...</span>
                )}
                {totalPages > getPageNumbers().slice(-1)[0] && (
                    <button 
                        onClick={() => handlePageChange(totalPages)} 
                        className={`pagination-button ${currentPage === totalPages ? 'active' : ''}`}
                    >
                        {totalPages}
                    </button>
                )}
                <button 
                    onClick={handleLast} 
                    disabled={currentPage === totalPages}
                    className="pagination-button"
                >
                    &raquo;
                </button>
            </div>
        </div>
    );
};

export default Pagination;

import React from 'react';
import "./Tabbar.css";

const Tabbar = ({links, active, onClick}) => {
    return (
        <div className="tabbar_container">
            {links.map((link, index) => (
                <div key={index} className={active == link.name ? "tabbar_tab tabbar_tab_active" : "tabbar_tab"}>
                    <p onClick={() => onClick(link)} className="tabbar_tab_name">{link.name}</p>
                </div>
            ))}
        </div>
    )
}

export default Tabbar;
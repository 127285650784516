import React, { useState } from "react";
import "./Table.css";
import NoData from "../NoData/NoData";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { Visibility } from "@mui/icons-material";

const Table = ({ data, headers, onEdit, onDelete, onView }) => {
    const [expandedRows, setExpandedRows] = useState([]);

    const renderExpandableContent = (documents) => {
        return (
            <table>
                <thead>
                    <tr>
                        {headers.sub.map((subHeader, index) => (
                            <th key={index}>{subHeader.label}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {documents.map((doc, index) => (
                        <tr key={index}>
                            {headers.sub.map((subHeader, subIndex) => (
                                <td key={subIndex}>
                                    {subHeader.name === 'actions' ? (
                                        headers.sub.find(h => h.name === "actions") && (
                                            <>
                                                {headers.sub.find(h => h.name === "actions").actions.includes("edit") && (
                                                    <EditOutlinedIcon
                                                        key="edit-icon"
                                                        className="clickable"
                                                        onClick={() => onEdit(doc)}
                                                    />
                                                )}
                                                {headers.sub.find(h => h.name === "actions").actions.includes("delete") && (
                                                    <DeleteOutlineOutlinedIcon
                                                        key="delete-icon"
                                                        className="clickable"
                                                        onClick={() => onDelete(doc)}
                                                    />
                                                )}
                                                {headers.sub.find(h => h.name === "actions")?.actions.includes("view") && 
                                                (doc.document || doc.receiptDocuments?.length > 0) && (
                                                    <Visibility
                                                        key="view-icon"
                                                        className="clickable"
                                                        onClick={() => onView(doc)}
                                                    />
                                                )}
                                            </>
                                        )
                                    ) : (
                                        // Render the document data based on subHeader.name
                                        doc[subHeader.name] ? (
                                            subHeader.name === 'document' ? (
                                                <a
                                                    href={doc[subHeader.name]}
                                                    className="table_document_link"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {doc[subHeader.name]}
                                                </a>
                                            ) : (
                                                doc[subHeader.name]
                                            )
                                        ) : null
                                    )}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    const getDocuments = (row) => {
        return row.repairDocuments || row.warrantyDocuments || row.invoiceDocuments || row.receiptDocuments || row.receipts || [];
    };

    const handleExpandClick = (rowIndex) => {
        if (expandedRows.includes(rowIndex)) {
            setExpandedRows(expandedRows.filter(index => index !== rowIndex));
        } else {
            setExpandedRows([...expandedRows, rowIndex]);
        }
    };

    if (data.length === 0) {
        return <NoData />;
    }

    const renderCell = (row, header) => {
        if (header.name === 'actions' && header.actions) {
            return (
                <>
                    {header.actions.includes("edit") && (
                        <EditOutlinedIcon
                            key="edit-icon"
                            className="clickable"
                            onClick={() => onEdit(row)}
                        />
                    )}
                    {header.actions.includes("delete") && (
                        <DeleteOutlineOutlinedIcon
                            key="delete-icon"
                            className="clickable"
                            onClick={() => onDelete(row)}
                        />
                    )}
                    
                    {header.actions.includes("view") && (
                        <Visibility
                            key="view-icon"
                            className="clickable"
                            onClick={() => onView(row)}
                        />
                    )}
                </>
            );
        }

        return row[header.name];
    };

    return (
        <table>
            <thead>
                <tr>
                    {headers.main.map((header, index) => (
                        <th key={index}>{header.label}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {data.map((row, rowIndex) => (
                    <React.Fragment key={rowIndex}>
                        <tr>
                            {headers.main.map((header, headerIndex) => (
                                <td key={headerIndex} className={headerIndex === 0 ? "expandable-icon-wrapper" : ""}>
                                    {headerIndex === 0 && getDocuments(row).length > 0 && (
                                        <span
                                            className="clickable expandable_icon"
                                            onClick={() => handleExpandClick(rowIndex)}
                                        >
                                            {expandedRows.includes(rowIndex) ? <ExpandMoreOutlinedIcon /> : <ChevronRightOutlinedIcon />}
                                        </span>
                                    )}
                                    {renderCell(row, header)}
                                </td>
                            ))}
                        </tr>

                        {expandedRows.includes(rowIndex) && (
                            <tr>
                                <td colSpan={headers.main.length}>
                                    {renderExpandableContent(getDocuments(row))}
                                </td>
                            </tr>
                        )}
                    </React.Fragment>
                ))}
            </tbody>
        </table>
    );
};

export default Table;


/*
{getDocuments(row).length > 0 && (
    <span className="clickable expandable_icon" onClick={() => handleExpandClick(rowIndex)}>
        {expandedRows.includes(rowIndex) ? <ExpandMoreOutlinedIcon /> : <ChevronRightOutlinedIcon/>}
    </span>
)}
*/
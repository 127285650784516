import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import { Provider } from 'react-redux'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import "./utils/sentry.js"
import store from './stores/store.js'
import axios from 'axios'

const queryClient = new QueryClient()
const baseURL = process.env.NODE_ENV === 'development' ? import.meta.env.VITE_DEV_API_URL : import.meta.env.VITE_API_URL
axios.create({
  baseURL: baseURL,
})

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <App />
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>,
)

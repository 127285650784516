import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import "./Button.css";
import React from 'react';

const Button = ({ title, loading, type, onClick, style }) => {
    return (
        <button disabled={loading} style={style} type={type} onClick={onClick} className="customButton">
        {loading ? <LoadingSpinner/> : title}
        </button>
    );
};
export default Button;
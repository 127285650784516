import React, { forwardRef } from "react";
import "./InvoiceInput.css";

const InvoiceInput = forwardRef(({className, label, value, onChange, inputClassName }, ref) => {
    return (
        <div className={`invoiceInput ${className}`}>
        <label>{label}</label>
        <input
            className={`invoiceInput_input ${inputClassName}`}
            value={value}
            onChange={onChange}
            disabled={true}
        />
        </div>
    );
});

    export default InvoiceInput;
import axios from "axios";
import { createFormData } from "../utils/helper";

export const validateUsername = async (username) => {
    try {
        const response = await axios.post(`${import.meta.env.VITE_API_URL}/users/validate/username`, {username: username});
        return response.data.data;
    } catch (error) {
        if(error.response) {
            throw new Error(error.response.data.message);
        }
        throw new Error(error.message);
    }
}

export const validateEmail = async (email) => {
    try {
        const response = await axios.post(`${import.meta.env.VITE_API_URL}/users/validate/email`, {email: email});
        return response.data.data;
    } catch (error) {
        if(error.response) {
            throw new Error(error.response.data.message);
        }
        throw new Error(error.message);
    }
}

export const updateUser = async (props) => {
    try {
        props.data.id = props.user.data.id;
        const formData = createFormData(props.data);
        const response = await axios.put(`${import.meta.env.VITE_API_URL}/users`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${props.user.token}`
              },
        });
        return response.data.data;
    } catch (error) {
        if(error.response) {
            throw new Error(error.response.data.message);
        }
        throw new Error(error.message);
    }
}
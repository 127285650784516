import React from "react";
import "./Modal.css";

const Modal = ({ children, show, onClose }) => {
    return (
        <div className={`modal ${show ? "show" : ""}`}>
        <div className="modal-content">
            <span className="close" onClick={onClose}>
            &times;
            </span>
            {children}
        </div>
        </div>
    );
};

export default Modal;
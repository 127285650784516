import React from "react";
import "./InvoiceCheckbox.css";

const InvoiceCheckbox = ({ title, checked }) => {
    return (
        <div className="invoice_checkbox_container">
            <input className="invoice_checkbox" checked={checked} type="checkbox" disabled={true}/>
            <label>{title}</label>
        </div>
    )
}

export default InvoiceCheckbox;
import React, { useState, useEffect } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import "./Toast.css";

const Toast = ({ title, text, type, onClose }) => {
    const [visible, setVisible] = useState(true);
    const [opacity, setOpacity] = useState(1);

    useEffect(() => {
        const fadeOutTimer = setTimeout(() => {
            setOpacity(0);
        }, 2500);

        const hideTimer = setTimeout(() => {
            setVisible(false);
            onClose(); // Call the onClose function to reset the parent state
        }, 3000);

        return () => {
            clearTimeout(fadeOutTimer);
            clearTimeout(hideTimer);
        };
    }, [onClose]);

    if (!visible) {
        return null;
    }

    const types = {
        success: {
            color: "#4caf50",
            backgroundColor: "#e8f5e9",
            icon: <CheckCircleIcon fontSize="large" style={{ color: "#4caf50" }} className="toast_icon" />
        },
        error: {
            color: "#f44336",
            backgroundColor: "#ffebee",
            icon: <ErrorIcon fontSize="large" style={{ color: "#f44336" }} className="toast_icon" />
        }
    }

    return (
        <div className="toast_container" style={{ opacity, backgroundColor: types[type].backgroundColor }}>
            <div className="toast_body">
                <span className='toast_color' style={{ backgroundColor: types[type].color }}></span>
                {types[type].icon}
                <div className="toast_content">
                    <h3 className="toast_title">{title}</h3>
                    <p className="toast_text">{text}</p>
                </div>
                <CloseIcon className="toast_close" />
            </div>
        </div>
    )
}

export default Toast;

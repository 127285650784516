import axios from "axios";
import { createFormData } from "../utils/helper";

export const getInvoices = async (props) => {
    try {
        const response = await axios.get(`${import.meta.env.VITE_API_URL}/users/${props.data.id}/invoices`, {
            headers: {
                Authorization: `Bearer ${props.token}`
            }
        });
        return response.data.data;
    } catch (error) {
        if(error.response) {
            throw new Error(error.response.data.message);
        }
        throw new Error(error.message);
    }
}

export const getInvoice = async (props) => {
    try {
        const response = await axios.get(`${import.meta.env.VITE_API_URL}/users/invoices/${props.id}`, {
            headers: {
                Authorization: `Bearer ${props.user.token}`
            }
        });
        return response.data.data;
    } catch (error) {
        if(error.response) {
            throw new Error(error.response.data.message);
        }
        throw new Error(error.message);
    }
}

export const createInvoice = async (props) => {
    try {
        props.data.file = props.pdf;
        props.data.id = props.id;
        const formData = createFormData(props.data);

        const response = await axios.post(`${import.meta.env.VITE_API_URL}/users/${props.user.data.id}/invoices`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${props.user.token}`,
            },
        });

        return response.data.data;
    } catch (error) {
        if (error.response) {
            throw new Error(error.response.data.message);
        }
        throw new Error(error.message);
    }
};

export const updateInvoice = async (props) => {
    try {
        props.data.file = props.pdf;
        props.data.id = props.id
        const formData = createFormData(props.data);
        
        const response = await axios.put(`${import.meta.env.VITE_API_URL}/users/${props.user.data.id}/invoices`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${props.user.token}`
            },
        });

        return response.data.data;
    } catch (error) {
        if(error.response) {
            throw new Error(error.response.data.message);
        }
        throw new Error(error.message);
    }
};

export const deleteInvoice = async (props) => {
    try {
        const response = await axios.delete(`${import.meta.env.VITE_API_URL}/users/${props.id}/invoices`, {
            headers: {
                Authorization: `Bearer ${props.user.token}`
            }
        });
        return response.data.data;
    } catch (error) {
        if(error.response) {
            throw new Error(error.response.data.message);
        }
        throw new Error(error.message);
    }
}

export const getNewInvoiceId = async (props) => {
    try {
        const response = await axios.get(`${import.meta.env.VITE_API_URL}/users/${props.data.id}/invoices/new`, {
            headers: {
                Authorization: `Bearer ${props.token}`
            }
        });
        return response.data.data;
    } catch (error) {
        if(error.response) {
            throw new Error(error.response.data.message);
        }
        throw new Error(error.message);
    }
}
import "./NoData.css";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

const NoData = () => {
    return (
        <div className="no_data">
            <ErrorOutlineOutlinedIcon color="action" sx={{fontSize: 80}}/>
            <p className="no_data_header">No Results Found.</p>
            <p className="no_data_subHeader">Try adding some data to populate this page!</p>
        </div>
    );
}

export default NoData;
import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../reducers/authReducer.js';
import { loadState, saveState } from '../utils/persistence.js';

const preloadedState = loadState();

const store = configureStore({
    reducer: {
        auth: authReducer
    },
    preloadedState
});

store.subscribe(() => {
    saveState(store.getState());
  });

export default store;
  

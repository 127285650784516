import React, { useEffect, useRef, useState } from "react";
import Layout from "../components/Layout/Layout";
import BackIcon from "../components/BackIcon/BackIcon";
import "../assets/styles/formScreen.css";
import ReusableForm from "../components/ReusableForm/ReusableForm";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { formConfigs } from "../utils/formConfigs";
import { getBasePath } from "../utils/helper";
import Toast from "../components/Toast/Toast";
import * as Sentry from "@sentry/react";
import { useSelector } from "react-redux";

const FormScreen = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const basePathName = getBasePath(location.pathname);
    const {id} = useParams();   
    const config = formConfigs[basePathName];

    const [data, setData] = useState({});
    const [showToast, setShowToast] = useState(false);
    const scrollRef = useRef(null);

    const mutation = useMutation({mutationFn: config.mutationFn})
    const user = useSelector((state) => state.auth.user);

    const handleSubmit = async (data) => {
        mutation.mutate({data, user}, {
            onSuccess: () => {
                navigate(-1);
            },
            onError: (error) => {
                Sentry.captureException(error);
                scrollRef.current.scrollTop = 0
                setShowToast(true);
            }
        });
    };

    const handleToastClose = () => {
        setShowToast(false);
    };

    const populateData = async () => {   
        if(config.getData) {
            setData(await config.getData({id, user}));
        }
    } 

    useEffect(() => {
        populateData();
    }, [id])

    return (
        <Layout ref={scrollRef} activeLink={config.active}>
        <div className="formScreen_header">
            <BackIcon/>
        </div>
        <h2>{config.header}</h2>
        <ReusableForm fields={config.fields} defaultValues={data} isPending={mutation.isPending} onSubmit={handleSubmit} />
        {showToast && <Toast title="Error" text="There was an error submitting the form" type="error" onClose={handleToastClose}/>}
        </Layout>
    )
}

export default FormScreen
